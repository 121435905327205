<template>
  <div class="bg-white">
    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="open">
      <Dialog class="relative z-40 lg:hidden" @click.self="open = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
              <div class="flex px-4 pb-2 pt-5">
                <button type="button" class="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400" @click="open = false">
                  <span class="absolute -inset-0.5" />
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Links -->
              <TabGroup as="div" class="mt-2">
                <div class="border-b border-gray-200">
                  <TabList class="-mb-px flex space-x-8 px-4">
                    <Tab as="template" v-for="category in navigation.categories" :key="category.name" v-slot="{ selected }">
                      <button :class="[selected ? 'border-gray-900 text-gray-900' : 'border-transparent text-gray-900', 'flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium']">{{ category.name }}</button>
                    </Tab>
                  </TabList>
                </div>
                <TabPanels as="template">
                  <TabPanel v-for="category in navigation.categories" :key="category.name" class="space-y-8 px-4 pb-8">
                    <div class="grid grid-cols-2 gap-x-4">
                      <div v-for="item in category.featured" :key="item.name" class="group relative text-sm">
                        <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                          <img :src="item.imageSrc" :alt="item.imageAlt" class="object-cover object-center" loading="lazy" />
                        </div>
                        <router-link :to="{ name: item.routeName }" class="mt-6 block font-medium text-gray-900" @click="open = false">
                          <span class="absolute inset-0 z-10" aria-hidden="true" />
                          {{ item.name }}
                        </router-link>
                        <p aria-hidden="true" class="mt-1">Vásároljon most!</p>
                      </div>
                    </div>
                    <div v-for="section in navigation.categories[0].sections" :key="section.id">
                      <template v-if="section.name">
                        <router-link :to="{ name: 'CategoryPage', params: { slug: section.slug } }" :id="`${section.category_id}-${section.name}-heading-mobile`" class="font-medium text-gray-900 hover:text-gray-800" @click="open = false">{{ section.name }}</router-link>
                      </template>
                      <ul role="list" :aria-labelledby="`${navigation.categories[0].id}-${section.id}-heading-mobile`" class="mt-6 flex flex-col space-y-6">
                        <li v-for="child in section.children" :key="child.id" class="flow-root"><router-link :to="child.category_id === 0 ? { name: 'SubmenuPage', params: { slug: child.slug } } : { name: 'CategoryPage', params: { slug: child.slug } }" class="-m-2 block p-2 text-gray-500" @click="dialogHandleClick(child, close)">{{ child.name }}</router-link></li>
                      </ul>
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
              <div class="space-y-6 border-t border-gray-200 px-4 py-6">
                <div v-for="page in navigation.pages" :key="page.name" class="flow-root">
                  <router-link :to="{ name: page.routeName }" class="-m-2 block p-2 font-medium text-gray-900" @click="open = false">{{ page.name }}</router-link>
                </div>
              </div>
              <!--div class="space-y-6 border-t border-gray-200 px-4 py-6">
                <div class="flow-root">
                  <router-link :to="{ name: 'LoginPage' }" class="-m-2 block p-2 font-medium text-gray-900" @click="open = false">Bejelentkezés</router-link>
                </div>
                <div class="flow-root">
                  <router-link :to="{ name: 'RegistrationPage' }" class="-m-2 block p-2 font-medium text-gray-900" @click="open = false">Regisztráció</router-link>
                </div>
              </div-->
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
    <header :class="[ isFixed ? 'fixed z-20 top-0 left-0 w-full bg-white shadow-lg' : 'relative bg-white', isVisible ? '' : '-translate-y-full', 'transition-transform duration-300 ease-in-out' ]">
      <div class="bg-gray-900">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="flex justify-end items-center py-1 ">
            <!--span>Magyar márka</span-->
            <router-link :to="{ name: 'ContactPage' }" class="flex items-center text-white space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="15px" height="15px" fill="#fff"><path d="M256 48C141.1 48 48 141.1 48 256l0 40c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-40C0 114.6 114.6 0 256 0S512 114.6 512 256l0 144.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24l-32 0c-26.5 0-48-21.5-48-48s21.5-48 48-48l32 0c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40L464 256c0-114.9-93.1-208-208-208zM144 208l16 0c17.7 0 32 14.3 32 32l0 112c0 17.7-14.3 32-32 32l-16 0c-35.3 0-64-28.7-64-64l0-48c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64l0 48c0 35.3-28.7 64-64 64l-16 0c-17.7 0-32-14.3-32-32l0-112c0-17.7 14.3-32 32-32l16 0z"/></svg><span>Segítség</span>
            </router-link >
          </div>
        </div>
      </div>

      <nav aria-label="Top" class="relative z-20 bg-white bg-opacity-90 backdrop-blur-xl backdrop-filter">
        <div class="border-b border-gray-200">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 items-center">
              <button type="button" class="relative rounded-md bg-white p-2 text-gray-400 lg:hidden" @click="open = true">
                <span class="absolute -inset-0.5" />
                <span class="sr-only">Open menu</span>
                <Bars3Icon class="h-6 w-6" aria-hidden="true" />
              </button>
              <!-- Logo -->
              <div class="ml-4 flex lg:ml-0">
                <router-link :to="{ name: 'HomePage' }">
                  <span class="sr-only">{{ appName }}</span>
                  <img class="h-8 w-auto" :src="appLogo" :alt="appName" loading="lazy" />
                </router-link>
              </div>
              <PopoverGroup class="hidden lg:ml-8 lg:block lg:self-stretch">
                <div class="flex h-full space-x-8">
                  <Popover v-for="category in navigation.categories" :key="category.name" class="flex" v-slot="{ open, close }">
                    <div class="relative flex">
                      <PopoverButton :class="[open ? 'border-gray-900 text-gray-900' : 'border-transparent text-gray-700 hover:text-gray-800', 'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out']">{{ category.name }}</PopoverButton>
                    </div>
                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
                      <PopoverPanel class="absolute inset-x-0 top-full bg-white text-sm text-gray-500">
                        <div class="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />
                        <div class="absolute inset-0 top-0 mx-auto h-px max-w-7xl px-8" aria-hidden="true">
                          <div :class="[open ? 'bg-gray-200' : 'bg-transparent', 'h-px w-full transition-colors duration-200 ease-out']" />
                        </div>
                        <div class="relative">
                          <div class="mx-auto max-w-7xl px-8">
                            <div class="grid grid-cols-[15%,70%] gap-6 py-12">
                              <div>
                                  <div v-for="section in navigation.categories[0].sections" :key="section.id" @mouseenter="hoverSection(section.id)" class="mb-2">
                                    <template v-if="section.name">
                                      <p :id="`${section.category_id}-${section.name}-heading`" class="flex items-center text-lg text-gray-900 hover:bg-gray-100 p-2 rounded"
                                         :class="{'bg-gray-100': hoverSectionId === section.id}">
                                        {{ section.name }}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="15px" height="15px" class="ml-auto">
                                          <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                                        </svg>
                                      </p>
                                    </template>
                                  </div>
                              </div>
                              <div>
                                <div v-if="hoverSectionId !== null" class="p-2">
                                  <ul role="list" :aria-labelledby="`${navigation.categories[0].id}-${hoverSectionId}-heading-mobile`" class=" flex flex-col space-y-6">
                                    <li v-for="child in getChildrenForSection(hoverSectionId)" :key="child.id" class="flow-root">
                                      <router-link :to="child.category_id === 0 ? { name: 'SubmenuPage', params: { slug: child.slug } } : { name: 'CategoryPage', params: { slug: child.slug } }" @click="navbarHandleClick(child, close)" class="-m-2 block p-2 text-gray-900 hover:text-gray-900">
                                        {{ child.name }}
                                      </router-link>
                                    </li>
                                  </ul>
                                  </div> 
                              </div>
                              <div class="col-span-1" v-if="category.featured">
                                <div v-for="item in category.featured" :key="item.name" class="group relative text-base sm:text-sm">
                                  <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                    <img :src="item.imageSrc" :alt="item.imageAlt" class="object-cover object-center" loading="lazy" />
                                  </div>
                                  <router-link :to="{ name: item.routeName }" class="mt-6 block font-medium text-gray-900">
                                    <span class="absolute inset-0 z-10" aria-hidden="true" />
                                    {{ item.name }}
                                  </router-link>
                                  <p aria-hidden="true" class="mt-1">Vásároljon most!</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </PopoverPanel>
                    </transition>
                  </Popover>
                  <router-link :to="{ name: page.routeName }" v-for="page in navigation.pages" :key="page.name" class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">{{ page.name }}</router-link>
                </div>
              </PopoverGroup>
              <div class="ml-auto flex items-center">
                <!--div class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  <router-link :to="{ name: 'LoginPage' }" class="text-sm font-medium text-gray-700 hover:text-gray-800">Bejelentkezés</router-link>
                  <span class="h-6 w-px bg-gray-200" aria-hidden="true" />
                  <router-link :to="{ name: 'RegistrationPage' }" class="text-sm font-medium text-gray-700 hover:text-gray-800">Regisztráció</router-link>
                </div-->
                <!-- Search -->
                <!--div class="flex lg:ml-6" @click="openSearch">
                  <div class="p-2 text-gray-400 hover:text-gray-500">
                    <span class="sr-only">Search</span>
                    <MagnifyingGlassIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div-->
                <!-- Compare -->
                <div class="ml-4 flow-root lg:ml-6">
                  <router-link :to="{ name: 'ComparePage' }"  class="group -m-2 flex items-center p-2 relative">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="h-6 w-6 text-gray-400 hover:text-gray-900" fill="currentColor"><path d="M384 32l128 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L398.4 96c-5.2 25.8-22.9 47.1-46.4 57.3L352 448l160 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-192 0-192 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l160 0 0-294.7c-23.5-10.3-41.2-31.6-46.4-57.3L128 96c-17.7 0-32-14.3-32-32s14.3-32 32-32l128 0c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32zm55.6 288l144.9 0L512 195.8 439.6 320zM512 416c-62.9 0-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8l95.2 163.2c5.7 9.8 9.3 21.1 6.7 32.1C627.2 382 574.9 416 512 416zM126.8 195.8L54.4 320l144.9 0L126.8 195.8zM.9 337.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8l95.2 163.2c5.7 9.8 9.3 21.1 6.7 32.1C242 382 189.7 416 126.8 416S11.7 382 .9 337.1z"/></svg>
                    <span class="ml-2 font-medium text-white rounded-full hover:bg-gray-900 bg-gray-600 w-5 h-5 text-center absolute bottom-0 right-0 text-xs leading-5" v-if="compareCount > 0">{{ compareCount }}</span>
                    <span class="sr-only">items in compare, view compare</span>
                  </router-link>
                </div>
                <!-- Favorite -->
                <div class="ml-4 flow-root lg:ml-6">
                  <router-link :to="{ name: 'FavoritePage' }"  class="group -m-2 flex items-center p-2 relative">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-6 w-6 text-gray-400 hover:text-gray-900" fill="currentColor"><path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8l0-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5l0 3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1s0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5l0 3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2l0-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"/></svg>
                    <span class="ml-2 font-medium text-white rounded-full hover:bg-gray-900 bg-gray-600 w-5 h-5 text-center absolute bottom-0 right-0 text-xs leading-5" v-if="favoriteCount > 0">{{ favoriteCount }}</span>
                    <span class="sr-only">items in favorite, view favorite</span>
                  </router-link>
                </div>
                <!-- Cart -->
                <div class="ml-4 flow-root lg:ml-6">
                  <router-link :to="{ name: 'CartPage' }"  class="group -m-2 flex items-center p-2 relative">
                    <ShoppingBagIcon class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    <span class="ml-2 font-medium text-white rounded-full hover:bg-gray-900 bg-gray-600 w-5 h-5 text-center absolute bottom-0 right-0 text-xs leading-5">{{ cartItemCount }}</span>
                    <span class="sr-only">items in cart, view bag</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>

  <SearchModal :open="isSearchModalOpen" @close="closeSearchModal" />
</template>

<script setup>
import { ref } from 'vue'
import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/vue/24/outline'

</script>

<script>
import { mapGetters, mapActions } from 'vuex';
import SearchModal from '@/components/SearchModal.vue';

export default {
  name: 'NavbarPage',
  computed: {
    ...mapGetters(['cartItemCount', 'appLogo', 'appName', 'apiUrl', 'favoriteCount', 'compareCount', 'localStorageEnabled']),
  },
  data() {
    return {
      open: false,
      isSearchModalOpen: false,
      lastScrollPosition: 0,
      scrollDirection: null,
      isFixed: false, 
      isVisible: true, 
      hoverSectionId: null,
      navigation: {
        categories: [
          {
            id: 'kategoriak',
            name: 'Kategóriák',
            /*featured: [
              {
                name: 'Akció',
                routeName: 'HomePage',
                imageSrc: 'https://tailwindui.com/plus/img/ecommerce-images/mega-menu-category-02.jpg',
                imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
              },
            ],*/
            sections: [
              
                
            ],
          },
        ],
        pages: [
          { name: 'GYIK', routeName: 'FAQPage' },
          { name: 'Kapcsolat', routeName: 'ContactPage' },
        ],
      },
    }
  },
  created() {
    this.fetchCategories();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(['setSelectedCategory']),
    openSearch() {
      this.isSearchModalOpen = true;
    },
    closeSearchModal() {
      this.isSearchModalOpen = false;
    },
    async fetchCategories() {
      const cachedProducts = localStorage.getItem(`category.sections`);

      if (cachedProducts && this.localStorageEnabled) {
        this.navigation.categories[0].sections = JSON.parse(cachedProducts);
      } else {
        try {
          const response = await fetch(`${this.apiUrl}/menus`);
          const data = await response.json();
          this.navigation.categories[0].sections = data;

          localStorage.setItem(`category.sections`, JSON.stringify(data));
        } catch (error) {
          console.error('Hiba a termék lekérdezésekor:', error);
        }
      }
    },
    closePopover(closeFunction) {
      closeFunction()
    },
    navbarHandleClick(child, close) {
      this.setSelectedCategory(child);
      this.closePopover(close);
    },
    dialogHandleClick(child, close) {
      this.setSelectedCategory(child);
      this.open = false;
    },
    handleScroll() {
      const currentScrollPosition = window.scrollY;

      if(currentScrollPosition < 10){
        this.isFixed = false; 
        return false;
      } 

      if (currentScrollPosition > this.lastScrollPosition) {
        this.scrollDirection = 'down';
        this.isVisible = false;
      } else if (currentScrollPosition < this.lastScrollPosition) {
        this.scrollDirection = 'up';
        this.isVisible = true; 
        this.isFixed = true;
      }

      if (currentScrollPosition === 0) {
        this.isFixed = false;
        this.isVisible = true;
      }

      this.lastScrollPosition = currentScrollPosition;
    },
    hoverSection(sectionId) {
      this.hoverSectionId = sectionId;
    },
    clearHover() {
      this.hoverSectionId = null;
    },
    getChildrenForSection(sectionId) {
      const section = this.navigation.categories[0].sections.find(s => s.id === sectionId);
      return section ? section.children : [];
    }
  },
  watch: {
    isSearchModalOpen(newValue) {
      if (newValue) {
        console.log('A kereső modál megnyílt.');
      } else {
        console.log('A kereső modál bezárult.');
      }
    },
    open(newValue){
      if (newValue) {
        console.log('A kereső modál megnyílt.');
      } else {
        console.log('A kereső modál bezárult.');
      }
    },
  },
}
</script>