<template>
  <div id="app">
    <CookieConsentModal v-if="!hasAcceptedCookies" />
    <NavbarPage v-if="!isDisabledNavbar" />
    <AdPage v-if="!isDisabledNavbar" />
    <router-view />
    <RecentlyViewedProductsPage v-if="!isDisabledNavbar" />
    <FooterPage />
    <ScrollToTop />
  </div>
</template>

<style>

</style>

<script>
import NavbarPage from './components/Navbar.vue';
import FooterPage from './components/Footer.vue';
import AdPage from './components/Ad.vue';
import RecentlyViewedProductsPage from './components/RecentlyViewedProducts.vue';
import CookieConsentModal from './components/CookieConsentModal.vue';
import ScrollToTop from './components/ScrollToTop.vue';

export default {
  name: 'App',
  components: {
    NavbarPage,
    FooterPage,
    CookieConsentModal,
    RecentlyViewedProductsPage,
    ScrollToTop,
    AdPage,
  },
  data() {
    return {
      hasAcceptedCookies: false,
    };
  },
  computed: {
    isDisabledNavbar() {
      return this.$route.name === 'CheckoutPage' || this.$route.name === 'RegistrationPage' || this.$route.name === 'LoginPage';
    },
    routerViewClass() {
      return !this.isDisabledNavbar ? 'pt-24' : '';
    },
  },
  mounted() {
    const cookies = document.cookie.split(';');
    this.hasAcceptedCookies = cookies.some(cookie => cookie.trim().startsWith('cookie_consent=true'));
  },
};
</script>