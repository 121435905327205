<template>
  <!-- Goa3 beépítés: Homeinfo - Shop - Fejléc alatt - 1920x240, 7757810 -->
  <div id="zone7757810" class="goAdverticum"></div>
  <!-- Goa3 beépítés: Homeinfo - Shop - Fejléc alatt - 640x300, 7757812 -->
  <div id="zone7757812" class="goAdverticum"></div>
</template>

<script>
export default {
  name: 'AdPage',
  mounted() {
    this.initAdverticum();
  },
  methods: {
    initAdverticum() {
      const desktopZone = document.getElementById('zone7757810');
      const mobileZone = document.getElementById('zone7757812');

      if (!desktopZone || !mobileZone) return;

      const zoneToRemove = screen.width < 779 ? desktopZone : mobileZone;
      const activeZone = zoneToRemove === mobileZone ? desktopZone : mobileZone;
      const ratio = zoneToRemove === mobileZone ? 240 / 1920 : 300 / 640;

      if (zoneToRemove && zoneToRemove.parentElement) {
        zoneToRemove.parentElement.removeChild(zoneToRemove);
      }

      activeZone.style.width = activeZone.clientWidth + 'px';
      activeZone.style.height = activeZone.clientWidth * ratio + 'px';
    },
  }
};
</script>